import React from 'react';

import styled, { baseline, scale, BREAKPOINTS } from '../styled';

import CenteredBox from './CenteredBox';

const Background = styled.div`
  @media (min-width: ${BREAKPOINTS.m}) {
    position: relative;
    overflow: hidden;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &:before {
    @media (min-width: ${BREAKPOINTS.m}) {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;

      background-color: #000;
    }
  }
`;

const Layout = styled(CenteredBox)`
  padding-top: ${scale(1)};
  padding-bottom: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.m}) {
    padding-top: ${scale(14)};
    padding-bottom: ${scale(6)};
  }
`;

const Heading = styled.h1`
  margin-top: 0;
  margin-bottom: ${scale(1)};

  ${baseline(28, 2, 'm')};

  @media (min-width: ${BREAKPOINTS.m}) {
    margin-bottom: ${scale(0)};

    ${baseline(72, 4, 'l')};
  }
`;

const SubHeading = styled.span`
  position: relative;
  top: ${scale(-0.5)};

  font-family: 'Open Sans';
  text-transform: uppercase;
  font-weight: bold;

  ${baseline(20, 2, 'm')};

  @media (min-width: ${BREAKPOINTS.m}) {
    top: ${scale(-2)};

    ${baseline(32, 2, 'l')};
  }
`;

const Presentation = styled.p`
  margin-bottom: 0;
  max-width: ${scale(35)};
`;

const Video = styled.video`
  display: none;

  @media (min-width: ${BREAKPOINTS.m}) {
    display: block;
    position: absolute;
    height: 100vh;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
`;

const VideoBlock: React.FC = (props) => {
  return (
    <Background {...props}>
      <Layout {...props}>
        <Heading>
          Un coursier à vélo
          <br />
          <SubHeading>pour vos plis et colis</SubHeading>
        </Heading>
        <Presentation>
          Green Course est <b>un service de livraison de lettres et marchandises à Nantes</b> et son
          agglomération. Nos coursiers à vélo assurent le transport de marchandises et de courrier
          jusqu’à 150 kilos, en centre-ville de Nantes ou périphérie. Optez pour un moyen de
          transport écologique pour expédier vos petits et gros colis !
        </Presentation>
      </Layout>
      <Video autoPlay poster="/videos/lets-start-the-day.jpg">
        <source src="/videos/lets-start-the-day.mp4" type="video/mp4" />
        <source src="/videos/lets-start-the-day.webm" type="video/webm" />
        <track default kind="captions" srcLang="fr" src="/videos/lets-start-the-day.vtt" />
      </Video>
    </Background>
  );
};

export default VideoBlock;
