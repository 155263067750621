import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import styled, { BREAKPOINTS, scale, baseline } from '../styled';

import { CenteredBoxMaxWidth } from './CenteredBox';
import ButtonLink from './ButtonLink';

const Layout = styled.div`
  @media (min-width: ${BREAKPOINTS.m}) {
    display: flex;
    padding-left: 0;
  }
`;

const Left = styled.div`
  @media (min-width: ${BREAKPOINTS.m}) {
    position: relative;
    width: 50%;
  }
`;

const Right = styled.div`
  box-sizing: border-box;
  padding: ${scale(2)} ${scale(1)};

  @media (min-width: ${BREAKPOINTS.m}) {
    width: ${scale(CenteredBoxMaxWidth.M / 2)};
    margin-right: auto;
    padding-top: ${scale(4)};
    padding-bottom: ${scale(4)};
    padding-left: ${scale(2)};
    padding-right: ${scale(1)};
  }
`;

const Image = styled(GatsbyImage)`
  max-height: ${scale(20)};

  @media (min-width: ${BREAKPOINTS.m}) {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: inherit;
  }
`;

const Title = styled.h2`
  font-family: 'Fredericka the great';
  font-weight: normal;
  text-transform: uppercase;
  ${baseline(28, 2, 'm')};

  margin-top: 0;
  margin-bottom: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.m}) {
    ${baseline(51, 3, 'l')};
  }
`;

const Content = styled.p`
  margin-top: 0;
  margin-bottom: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.m}) {
    margin-bottom: ${scale(2)};
  }
`;

const GreenCourseSpirit: React.FC<{
  img: IGatsbyImageData;
}> = (props) => {
  return (
    <Layout>
      <Left>
        <Image image={props.img} alt="" />
      </Left>
      <Right>
        <Title>L’esprit Green Course</Title>
        <Content>
          Entreprise à taille humaine, nous livrons chaque colis avec attention et bonne humeur
          depuis 2013. Profitez d’une prise en charge personnalisée et d’une grande flexibilité pour
          vos courses, livrées en toute sécurité et en respectant le code de la route. La livraison
          par coursier implique une relation de confiance :{' '}
          <strong>découvrez nos cyclistes !</strong>
        </Content>
        <ButtonLink to="/propos">Découvrir l’équipe</ButtonLink>
      </Right>
    </Layout>
  );
};

export default GreenCourseSpirit;
