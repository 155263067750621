import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { SerializedStyles, css, Theme } from '@emotion/react';

import styled, { BREAKPOINTS, scale, baseline, hachureStyles } from '../styled';

import CenteredBox from './CenteredBox';

export enum ImagePosition {
  Left = 'left',
  Right = 'right',
}

function blockHachureStyles({
  imgPosition,
  theme,
}: {
  imgPosition: ImagePosition;
  theme: Theme;
}): SerializedStyles {
  return css`
    &:before {
      content: '';
      ${hachureStyles(theme.hachure.m)};
      width: 100%;
      height: ${scale(18)};
      position: absolute;
      left: 0;
      right: 0;
      top: ${scale(1)};

      @media (min-width: ${BREAKPOINTS.m}) {
        top: 0;
        bottom: 0;
        width: 35%;
        height: 100%;
        ${imgPosition === ImagePosition.Left && `right: inherit;`};
        ${imgPosition === ImagePosition.Right && `left: inherit;`};
      }
    }
  `;
}

const Wrapper = styled.div`
  position: relative;
`;

const Layout = styled(CenteredBox)`
  display: flex;
  flex-direction: column;
  padding-top: ${scale(1)};
  padding-bottom: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.m}) {
    flex-direction: row;
    padding-top: ${scale(4)};
    padding-bottom: ${scale(4)};
  }
`;

const Left = styled.div<{ imgPosition: ImagePosition }>`
  padding-top: ${scale(1)};

  ${blockHachureStyles};

  @media (min-width: ${BREAKPOINTS.m}) {
    padding-top: 0;
    padding-right: ${({ imgPosition }): string =>
      imgPosition === ImagePosition.Left ? '0' : scale(2)};
    width: ${({ imgPosition }): string => (imgPosition === ImagePosition.Left ? '45%' : '55%')};
  }
`;

const Right = styled.div<{ imgPosition: ImagePosition }>`
  padding-top: ${scale(1)};
  order: ${({ imgPosition }): string => (imgPosition === ImagePosition.Left ? 'initial' : '-1')};
  z-index: 1;

  @media (min-width: ${BREAKPOINTS.m}) {
    padding-top: 0;
    padding-left: ${({ imgPosition }): string =>
      imgPosition === ImagePosition.Left ? scale(2) : '0'};
    order: initial;
    width: ${({ imgPosition }): string => (imgPosition === ImagePosition.Left ? '55%' : '45%')};
  }
`;

const Image = styled(GatsbyImage)`
  height: ${scale(20)};
  border-radius: ${scale(2)};

  @media (min-width: ${BREAKPOINTS.m}) {
    max-height: inherit;
  }
`;

const Title = styled.h2`
  font-family: 'Fredericka the great';
  font-weight: normal;
  text-transform: uppercase;
  ${baseline(28, 2, 'm')};

  margin-top: 0;
  margin-bottom: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.m}) {
    ${baseline(51, 3, 'l')};
  }
`;

const Content = styled.div`
  margin-top: 0;
  margin-bottom: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.m}) {
    margin-bottom: ${scale(2)};
  }
`;

const Actions = styled.div`
  > * {
    margin-bottom: ${scale(1)};

    &:not(:last-child) {
      margin-right: ${scale(1)};
    }
  }
`;

const ServiceBlock: React.FC<{
  img: IGatsbyImageData;
  imgPosition?: ImagePosition;
}> & { Title: typeof Title; Content: typeof Content; Actions: typeof Actions } = ({
  img,
  imgPosition = ImagePosition.Left,
  ...props
}) => {
  return (
    <Wrapper>
      <Layout>
        {imgPosition === ImagePosition.Left && (
          <React.Fragment>
            <Left imgPosition={imgPosition}>
              <Image image={img} alt="" />
            </Left>
            <Right imgPosition={imgPosition}>{props.children}</Right>
          </React.Fragment>
        )}
        {imgPosition === ImagePosition.Right && (
          <React.Fragment>
            <Left imgPosition={imgPosition}>{props.children}</Left>
            <Right imgPosition={imgPosition}>
              <Image image={img} alt="" />
            </Right>
          </React.Fragment>
        )}
      </Layout>
    </Wrapper>
  );
};

ServiceBlock.Title = Title;
ServiceBlock.Content = Content;
ServiceBlock.Actions = Actions;

export default ServiceBlock;
