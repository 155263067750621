import React from 'react';
import { SerializedStyles } from '@emotion/react';

import * as GatsbyTypes from '../graphqlTypes';
import styled, { scale, BREAKPOINTS, hachureStyles } from '../styled';

import Carousel from './Carousel';
import CenteredBox from './CenteredBox';
import ButtonLink from './ButtonLink';
import OutboundLink from './OutboundLink';

const Background = styled.div`
  ${({ theme }): SerializedStyles => hachureStyles(theme.hachure.l)};
`;

const TestimoniesCarousel = styled(Carousel)`
  position: relative;
  margin-bottom: ${scale(3)};
  overflow: visible;

  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/images/testimony-background.jpg');
  border-radius: ${scale(2)};

  @media (min-width: ${BREAKPOINTS.m}) {
    margin-bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: ${scale(2)};
    background-color: rgba(0, 0, 0, 0.9);
  }

  nav {
    position: absolute;
    bottom: ${scale(-2)};
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0;
  }
`;

const Layout = styled(CenteredBox)`
  padding-top: ${scale(2)};
  padding-bottom: ${scale(2)};
`;

const Slide = styled.div`
  position: relative;
  width: 100%;
  padding: ${scale(2)} ${scale(1)};

  color: #fff;
  text-align: center;

  @media (min-width: ${BREAKPOINTS.m}) {
    padding: ${scale(4)} ${scale(8)};
  }
`;

const Testimony = styled.blockquote`
  margin: 0 ${scale(1)} ${scale(1)};
  padding-top: ${scale(3)};

  font-weight: bold;

  @media (min-width: ${BREAKPOINTS.m}) {
    margin-bottom: ${scale(2)};
  }

  &:before {
    width: ${scale(2.75)};
    height: ${scale(2.75)};
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path fill="%23fff" d="M91.2 25.3c-2.8-4.5-7.1-7.5-12.3-8.4-5.4-1-11.1.4-15.5 3.8C59 24 56.2 29 55.7 34.4c-.5 5.4 1.1 10.6 4.6 14.7 2.9 3.4 6.7 5.7 10.9 6.6l-1.6 25.2.2.5c.4 1.1 1.4 1.8 2.5 1.9h.2c1.1 0 2.1-.6 2.5-1.5.9-1.8 2-3.8 3.2-6.1 5.4-10.1 12.7-24 15.1-31.8 1.9-6.4 1.2-13.3-2.1-18.6zm-3.1 16.9c-1.9 6.2-7.3 16.9-12.1 26l1-17.4-2.7-.2c-3.8-.3-7.3-2.1-9.8-5-2.5-2.9-3.7-6.7-3.3-10.6.4-3.9 2.3-7.4 5.5-9.8 2.6-2 5.7-3 8.8-3 .8 0 1.6.1 2.4.2 4.6.8 7.2 3.6 8.6 5.8 2.5 3.9 3 9.2 1.6 14zM28.9 17c-5.4-1-11.1.4-15.5 3.8s-7.2 8.3-7.7 13.7c-.5 5.4 1.1 10.6 4.6 14.7 2.9 3.4 6.7 5.7 10.9 6.6L19.6 81l.2.5c.4 1.1 1.4 1.8 2.5 1.9h.2c1.1 0 2-.6 2.5-1.5.9-1.8 2-3.8 3.2-6.1 5.4-10.1 12.7-24 15.1-31.8 1.9-6.3 1.1-13.2-2.1-18.5-2.7-4.7-7.1-7.6-12.3-8.5zm9.2 25.2c-1.9 6.2-7.3 16.9-12.1 26l1-17.4-2.7-.2c-3.8-.3-7.3-2.1-9.8-5-2.5-2.9-3.7-6.7-3.3-10.6.4-3.9 2.3-7.4 5.5-9.8 2.6-2 5.7-3 8.8-3 .8 0 1.6.1 2.4.2 4.6.8 7.2 3.6 8.6 5.8 2.5 3.9 3 9.2 1.6 14z"/></svg>');
  }
`;

const CompanyName = styled.p`
  margin-bottom: 0;

  font-weight: bold;
`;

const CompanyWebsite = styled(OutboundLink)`
  color: #fff;
  font-weight: normal;
`;

const Actions = styled.p`
  margin-bottom: 0;

  text-align: center;

  @media (min-width: ${BREAKPOINTS.m}) {
    position: relative;
    top: ${scale(-1)};
    z-index: 1;
  }
`;

const TestimoniesBlock: React.FC<{
  id: string;
  testimonies: GatsbyTypes.MarkdownRemarkFrontmatterTestimonies[];
}> = ({ id, testimonies, ...props }) => {
  const isDesktop =
    typeof window !== 'undefined' && window.matchMedia(`(min-width: ${BREAKPOINTS.m})`).matches;

  return (
    <Background {...props}>
      <Layout>
        <TestimoniesCarousel
          id={id}
          configs={[]}
          hasNavigation={isDesktop}
          hasPagination={!isDesktop}
        >
          {testimonies.map((testimony) => {
            /* istanbul ignore next */
            if (!testimony.website || !testimony.company || !testimony.quotation) return;

            const website = new URL(testimony.website).hostname;

            return (
              <Slide key={testimony.company}>
                <Testimony>{testimony.quotation}</Testimony>
                <CompanyName>{testimony.company}</CompanyName>
                <CompanyWebsite href={testimony.website} target="_blank" rel="noopener noreferrer">
                  {website}
                </CompanyWebsite>
              </Slide>
            );
          })}
        </TestimoniesCarousel>
        <Actions>
          <ButtonLink to="/clients">Voir tous les clients</ButtonLink>
        </Actions>
      </Layout>
    </Background>
  );
};

export default TestimoniesBlock;
