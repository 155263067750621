import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as GatsbyTypes from '../graphqlTypes';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import GreenCourseSpirit from '../components/GreenCourseSpirit';
import ServiceBlock, { ImagePosition } from '../components/ServiceBlock';
import RequestQuoteBlock from '../components/RequestQuoteBlock';
import ReassuranceBlock from '../components/ReassuranceBlock';
import ButtonLink from '../components/ButtonLink';
import VideoBlock from '../components/VideoBlock';
import TestimoniesBlock from '../components/TestimoniesBlock';
import InstagramBlock from '../components/InstagramBlock';

const HomePage: React.FC = () => {
  const { file } = useStaticQuery<GatsbyTypes.GetHomePageQuery>(
    graphql`
      query GetHomePage {
        file(name: { eq: "index" }) {
          id
          childMarkdownRemark {
            id
            frontmatter {
              title
              seo {
                title
                description
                image {
                  publicURL
                }
              }
              image_1 {
                childImageSharp {
                  gatsbyImageData(width: 600, layout: CONSTRAINED)
                }
              }
              image_2 {
                childImageSharp {
                  gatsbyImageData(width: 600, layout: CONSTRAINED)
                }
              }
              image_3 {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              testimonies {
                quotation
                company
                website
              }
            }
          }
        }
      }
    `
  );

  const seoData = file?.childMarkdownRemark?.frontmatter?.seo;
  /* istanbul ignore next */
  if (!seoData?.title || !seoData.description) {
    throw new Error('[Missing data] Page SEO meta');
  }

  const pageData = file?.childMarkdownRemark?.frontmatter;
  /* istanbul ignore next */
  if (!pageData?.title) {
    throw new Error('[Missing data] Page content');
  }

  const image1Img = pageData.image_1?.childImageSharp?.gatsbyImageData;
  const image2Img = pageData.image_2?.childImageSharp?.gatsbyImageData;
  const image3Img = pageData.image_3?.childImageSharp?.gatsbyImageData;

  return (
    <Layout isHomePage={true} hasBlocks={false}>
      <SEO
        title={seoData.title}
        description={seoData.description}
        image={seoData.image?.publicURL}
      />
      <VideoBlock />

      {image1Img && (
        <ServiceBlock img={image1Img}>
          <ServiceBlock.Title>Nos services de livraison à vélo</ServiceBlock.Title>
          <ServiceBlock.Content>
            <p>
              Green Course propose plusieurs <strong>services pour les professionnels.</strong>
            </p>
            <p>
              Vous êtes un professionnel et avez besoin de transmettre des marchandises à vos
              clients ou prestataires ? Découvrez la course ponctuelle : nous procédons à
              l’enlèvement de votre courrier ou votre colis et le livrons en{' '}
              <strong>main propre.</strong>
            </p>
            <p>
              Vous devez régulièrement vous déplacer pour relever une boîte postale ? Nous{' '}
              <strong>collectons pour vous votre courrier</strong> et vous le remettons en main
              propre à votre bureau.
            </p>
          </ServiceBlock.Content>
          <ServiceBlock.Actions>
            <ButtonLink to="/services/livraison-colis">Course ponctuelle</ButtonLink>
            <ButtonLink to="/services/courrier-entreprise">Service tournée</ButtonLink>
          </ServiceBlock.Actions>
        </ServiceBlock>
      )}
      {image2Img && (
        <ServiceBlock img={image2Img} imgPosition={ImagePosition.Right}>
          <ServiceBlock.Title>Livraison express à Nantes</ServiceBlock.Title>
          <ServiceBlock.Content>
            <p>
              Faire appel à un coursier à vélo, c’est aussi choisir un mode de{' '}
              <strong>livraison rapide !</strong>
            </p>
            <p>
              Ce qui nous donne une longueur d’avance, c’est que nos coursiers ne subissent pas les
              aléas du trafic lors de leurs livraisons. Vos lettres et colis traversent Nantes en
              moins de 45 minutes avec notre course urgente, pour un transport express !
            </p>
          </ServiceBlock.Content>
          <ServiceBlock.Actions>
            <ButtonLink to="/services/transport-urgent">Découvrir la livraison urgente</ButtonLink>
          </ServiceBlock.Actions>
        </ServiceBlock>
      )}
      <RequestQuoteBlock data-testid="block-request-quote" />
      {image3Img && <GreenCourseSpirit img={image3Img} />}
      <ReassuranceBlock id="block-reassurance" data-testid="block-reassurance" />
      <TestimoniesBlock
        id="block-testimonies"
        testimonies={pageData.testimonies as GatsbyTypes.MarkdownRemarkFrontmatterTestimonies[]}
      />
      <InstagramBlock />
    </Layout>
  );
};

export default HomePage;
