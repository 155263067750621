import React from 'react';

import { SocialNetworkLinks } from '../pages/contact';
import styled, { baseline, scale, BREAKPOINTS } from '../styled';

import CenteredBox from './CenteredBox';
import { IconInstagram } from './icons';
import SocialIconLink from './SocialIconLink';

const Layout = styled(CenteredBox)`
  padding-top: ${scale(1)};
  padding-bottom: ${scale(1)};

  text-align: center;

  @media (min-width: ${BREAKPOINTS.m}) {
    padding-top: ${scale(4)};
    padding-bottom: ${scale(4)};
  }
`;

const Heading = styled.h2`
  margin-top: 0;
  margin-bottom: 0;

  font-family: 'Fredericka the great';
  text-transform: uppercase;
  font-weight: normal;
  ${baseline(28, 2, 'm')};

  @media (min-width: ${BREAKPOINTS.m}) {
    ${baseline(51, 3, 'l')};
  }
`;

const SubHeading = styled.p`
  font-weight: bold;

  ${baseline(18, 1, 'm')};

  @media (min-width: ${BREAKPOINTS.m}) {
    ${baseline(20, 1, 'm')};
  }
`;

const InstagramBlock: React.FC = (props) => {
  return (
    <Layout {...props}>
      <Heading>La “green team”</Heading>
      <SubHeading>Retrouvez-nous sur Instagram</SubHeading>
      <SocialIconLink href={SocialNetworkLinks.Instagram} icon={<IconInstagram scale={1} />}>
        Instagram
      </SocialIconLink>
    </Layout>
  );
};

export default InstagramBlock;
